<template>
  <div class="px-normal pt-sm text-left pb-28">
    <div class="flex-none block pb-2">
      <div class="">
        <h3 class="text-xl font-bold">Foodies seleccionados ({{ foodieMirrorList.length }})</h3>
<!--        <h3 class="text-xl font-bold">Foodies seleccionados ({{ foodiesSelected.length }})</h3>-->
<!--        <h3 class="text-xl font-bold">Foodies seleccionados ({{ foodieMirrorList.length }})</h3>-->
      </div>
    </div>
    <div class="flex-auto flex mt-2 pb-2 transition-all" v-for="foodie in foodiesSelected" :key="foodie" >
      <div class="flex border rounded-full w-full p-1 items-center">
        <a-initials :src="foodie.profile_picture" :name="foodie.name" class="flex-none" :class="!foodieMirrorList.find(value => value.id === foodie.id) ? 'opacity-30' : ''"/>
        <h2 class="pl-2 flex-auto">{{ foodie.ig_username ? '@' + foodie.ig_username : foodie.name }}</h2>
        <div @click="foodieHandler(foodie)"
             class="custom-size flex-none text-center text-xl">
          <i class="align-middle" style="transition: all 0.3s ease-out;" :class="foodieMirrorList.find(value => value.id === foodie.id) ? 'icon-cancel' : 'icon-add_2 text-green-400'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step-foodies-selected',
  props: {
    foodiesSelected: { required: false },
    foodies: { required: false },
    foodiesObject: { required: false },
    foodiesMeeting: { required: false }
  },
  emits: ['update:foodies', 'disable-next', 'update:foodiesObject'],
  data () {
    return {
      foodieList: [],
      foodieMirrorList: []
    }
  },
  methods: {
    foodieHandler (foodie) {
      const find = this.foodieMirrorList.includes(foodie)
      console.log(find)
      if (find) {
        const pos = this.foodieMirrorList.indexOf(foodie)
        this.foodieMirrorList.splice(pos, 1)
        this.foodieList.splice(pos, 1)
      } else {
        this.foodieMirrorList.push(foodie)
        this.foodieList.push(foodie.id)
      }
      this.$emit('update:foodies', this.foodieList)
      this.$emit('update:foodiesObject', this.foodieMirrorList)

      // if (this.foodieList.length > 0) {
      //   this.$emit('disable-next', false)
      // } else {
      //   this.$emit('disable-next', true)
      // }
      if (this.foodiesMeeting && this.foodieList.length < 2) {
        this.$emit('disable-next', true)
      } else if (!this.foodiesMeeting && this.foodieList.length < 0) {
        this.$emit('disable-next', true)
      } else {
        this.$emit('disable-next', false)
      }
    },
    initializeList (foodieMirrorList, foodieList) {
      // this.foodieList = this.foodiesSelected
      this.foodiesSelected.forEach(function (foodie) {
        foodieMirrorList.push(foodie)
        foodieList.push(foodie.id)
      })
      this.$emit('update:foodies', this.foodieList)
      this.$emit('update:foodiesObject', this.foodieMirrorList)

      if (this.foodiesMeeting && this.foodieList.length < 2) {
        this.$emit('disable-next', true)
      } else if (!this.foodiesMeeting && this.foodieList.length < 0) {
        this.$emit('disable-next', true)
      } else {
        this.$emit('disable-next', false)
      }
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.initializeList(this.foodieMirrorList, this.foodieList)
    // this.foodiesList = this.foodiesSelected
    // this.foodieMirrorList = this.foodiesList
  }
}
</script>

<style scoped>
.custom-size{
  width: 30px;
  height: 30px;
}
</style>
