<template>
  <div class="flex flex-col px-normal pt-sm text-left content-between text-left">
    <div class="flex-1">
      <h3 class="text-xl font-bold">Tipo de colaboración</h3>
    </div>

    <div class="wrapper flex-1">
      <input type="radio" name="select" id="option-1" value="public" v-model="picked" @change="$emit('update:type', $event.target.value); collabType = true ">
      <input type="radio" name="select" id="option-2" value="private" v-model="picked" @change="$emit('update:type', $event.target.value); collabType = false">
      <label for="option-1" class="flex option option-1 border border-dgray p-normal mt-4">
        <span class="flex-auto">
          <span class="title block text-xl font-bold">Pública</span>
          <span class="text block">Define las condiciones y recibe solicitudes de foodies</span>
        </span>
        <span class="flex-none self-end ml-2">
          <button @click="$emit('update:type', 'public');$emit('go-next')" class="border border-dgray rounded-full px-4 py-small">Elegir</button>
<!--          <button :disabled="!collabType" @click="$emit('go-next')" class="border border-dgray rounded-full px-4 py-small">Elegir</button>-->
        </span>
      </label>
      <label for="option-2" class="flex option option-2 border border-dgray p-normal mt-4">
        <span class="flex-auto">
          <span class="title block text-xl font-bold">Por Invitación</span>
          <span class="text block">Define las condiciones y elige los foodies con los que quieres colaborar</span>
        </span>
        <div class="flex-none self-end ml-2">
          <button @click="$emit('update:type', 'private');$emit('go-next')" class="border border-dgray rounded-full px-4 py-small">Elegir</button>
<!--          <button :disabled="collabType" @click="$emit('go-next')" class="border border-dgray rounded-full px-4 py-small">Elegir</button>-->
        </div>
      </label>
    </div>

    <div class="flex-1 flex items-end pb-4 mt-4">
      <h1>*En todo momento puedes volver atrás y editar tus selecciones</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step-1',
  props: {
    type: { required: false }
  },
  emits: ['update:type', 'go-next'],
  data () {
    return {
      isActive: false,
      picked: '',
      collabType: true
    }
  },
  methods: {
    // test (value) {
    //   console.log(value)
    //   this.collabType = value === 'public'
    //   this.$emit('update:type', value)
    // }
  },
  created () {
    this.picked = this.type
    this.collabType = this.picked === 'public'
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>

.wrapper .option{
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  transition: all 0.3s ease;
}

/*.wrapper .option .dot::before{*/
/*  position: absolute;*/
/*  content: "";*/
/*  top: 4px;*/
/*  left: 4px;*/
/*  width: 12px;*/
/*  height: 12px;*/
/*  background: #0069d9;*/
/*  border-radius: 50%;*/
/*  opacity: 0;*/
/*  transform: scale(1.5);*/
/*  transition: all 0.3s ease;*/
/*}*/
input[type="radio"]{
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2{
  /*border-color: #cf1a1a;*/
  background-color: #8E8E8E;
  color: #fff;
}

#option-1:checked:checked ~ .option-1 button,
#option-2:checked:checked ~ .option-2 button{
  /*border-color: #cf1a1a;*/
  background-color: #397EF3;
  box-shadow: 0 2px 5px 1px #605858;
  border: none;
}

/*.wrapper .option span{*/
/*  font-size: 20px;*/
/*  color: #808080;*/
/*}*/
</style>
