<template>
  <a-stepper :slots="10" class="h-screen flex flex-col pt-14 pb-12">

    <template #header="{ index, goPrev }">
      <div @click="prev(index, goPrev)" class="p-4 h-14 z-40 block my-auto fixed top-0 bg-white max-w-5xl mx-auto inset-x-0 lg:top-14 text-left cursor-pointer">
        <i class="icon-arrowLeft inline-block"></i>
        <h3 class="uppercase inline-block font-bold">atrás</h3>
      </div>
      <!--      <div v-else class="px-4 pt-4 fixed top-0 bg-white max-w-5xl mx-auto inset-x-0 lg:top-auto text-left">-->
      <!--        <h3 class="uppercase">nueva colaboración</h3>-->
      <!--      </div>-->
    </template>

    <template #step-1="{ goNext }">
      <step1
        v-model:type="collaboration.type"
        @go-next="goNext"
      />
    </template>

    <template #step-2="{ goNext }">
      <step2
        v-model:foodies-meeting="collaboration.foodies_meeting"
        @go-next="goNext"
      />
<!--        v-model:discount-type="collaboration.discount_type"-->
<!--        v-model:discount-value="collaboration.discount_value"-->
    </template>

    <template #step-3>
      <step-followers
        v-model:followers="collaboration.min_ig_followers"
        @disable-next="disableNext = $event"
        v-model:foodies="foodies"
      />
    </template>

    <template #step-4>
      <step-foodies-count
        v-if="collaboration.type === 'public'"
        v-model:companions="collaboration.uses_limit"
        v-model:modal="modal.uses_limit"
      />
      <step-foodies
        v-else
        v-model:foodies-list="foodies"
        v-model:foodies-selected="selectedFoodies"
        :followers="collaboration.min_ig_followers"
        @disable-next="disableNext = $event"
      />
    </template>

    <template #step-5>
      <step-companions
        v-if=" collaboration.type === 'public' && !collaboration.foodies_meeting"
        v-model:companions="collaboration.companions_limit"
      />
      <step-calendar
        v-else-if="collaboration.type === 'public' && collaboration.foodies_meeting"
        v-model:start-at="collaboration.start_at"
        v-model:end-at="collaboration.end_at"
        v-model:week-days="collaboration.allowed_week_days"
        :foodies-meeting="collaboration.foodies_meeting"
        @disable-next="disableNext = $event"
      />
      <step-foodies-selected
        v-else-if="collaboration.type === 'private'"
        v-model:foodies-selected="selectedFoodies"
        v-model:foodies="collaboration.foodies"
        v-model:foodies-object="foodiesSelectedObjects"
        :foodies-meeting="collaboration.foodies_meeting"
        @disable-next="disableNext = $event"
      />
    </template>

    <template #step-6>
      <step-requirements
        v-if="collaboration.type === 'public' && collaboration.foodies_meeting"
        v-model:requirements="collaboration.requirements"
      />
      <step-discount
        v-if="collaboration.type === 'public' && !collaboration.foodies_meeting"
        v-model:discount-type="collaboration.discount_type"
        v-model:discount-value="collaboration.discount_value"
      />
      <step-companions
        v-if="collaboration.type === 'private' && !collaboration.foodies_meeting"
        v-model:companions="collaboration.companions_limit"
      />
      <step-calendar
        v-if="collaboration.type === 'private' && collaboration.foodies_meeting"
        v-model:start-at="collaboration.start_at"
        v-model:end-at="collaboration.end_at"
        v-model:week-days="collaboration.allowed_week_days"
        :foodies-meeting="collaboration.foodies_meeting"
        @disable-next="disableNext = $event"
      />
    </template>

    <template #step-7>
      <step-resume
        v-if="collaboration.type === 'public' && collaboration.foodies_meeting"
        v-model:collaboration="collaboration"
        v-model:create-modal="modal.create"
        v-model:error-modal="modal.error"
        v-model:foodie-values="collaboration.foodies"
      />
      <step-calendar
        v-if="collaboration.type === 'public' && !collaboration.foodies_meeting"
        v-model:start-at="collaboration.start_at"
        v-model:end-at="collaboration.end_at"
        v-model:week-days="collaboration.allowed_week_days"
        :foodies-meeting="collaboration.foodies_meeting"
        @disable-next="disableNext = $event"
      />
      <step-requirements
        v-if="collaboration.type === 'private' && collaboration.foodies_meeting"
        v-model:requirements="collaboration.requirements"
      />
      <step-discount
        v-if="collaboration.type === 'private' && !collaboration.foodies_meeting"
        v-model:discount-type="collaboration.discount_type"
        v-model:discount-value="collaboration.discount_value"
      />
    </template>

    <template #step-8>
      <step-requirements
        v-if="collaboration.type === 'public' && !collaboration.foodies_meeting"
        v-model:requirements="collaboration.requirements"
      />
      <step-calendar
        v-if="collaboration.type === 'private' && !collaboration.foodies_meeting"
        v-model:start-at="collaboration.start_at"
        v-model:end-at="collaboration.end_at"
        v-model:week-days="collaboration.allowed_week_days"
        v-model:foodies-meeting="collaboration.foodies_meeting"
        @disable-next="disableNext = $event"
      />
      <step-resume
        v-if="collaboration.type === 'private' && collaboration.foodies_meeting"
        v-model:collaboration="collaboration"
        v-model:create-modal="modal.create"
        v-model:error-modal="modal.error"
        v-model:foodie-values="foodiesSelectedObjects"
      />
    </template>

    <template #step-9>
      <step-requirements
        v-if="collaboration.type === 'private' && !collaboration.foodies_meeting"
        v-model:requirements="collaboration.requirements"
      />
      <step-resume
        v-if="collaboration.type === 'public' && !collaboration.foodies_meeting"
        v-model:collaboration="collaboration"
        v-model:create-modal="modal.create"
        v-model:error-modal="modal.error"
        v-model:foodie-values="collaboration.foodie"
      />
    </template>

    <template #step-10>
      <step-resume
        v-model:collaboration="collaboration"
        v-model:create-modal="modal.create"
        v-model:error-modal="modal.error"
        v-model:foodie-values="foodiesSelectedObjects"
      />
    </template>

    <template #footer="{ index, goNext }">
      <div class="fixed px-4 max-w-5xl mx-auto bottom-24 inset-x-0 lg:bottom-12">
        <button v-if="index > 2"
                :disabled="disableNext || loading.createCollab"
                @click="stepHandler(index, goNext)"
                class="bg-primary text-white w-full rounded-full py-sm overflow-hidden disabled:bg-gray">
          <span v-if="loading.createCollab">
            <a-loader class="h-6 w-6 block mx-auto" :color="'text-white'"/>
          </span>
          <span v-else>
            {{ getCollabText(index) }}
          </span>
        </button>
      </div>
    </template>

  </a-stepper>
</template>

<script>
import step1 from './steps/step-1'
import step2 from './steps/step-2'
import stepFollowers from './steps/step-followers'
import stepFoodiesCount from './steps/step-foodies-count'
import stepCompanions from './steps/step-companions'
import stepCalendar from './steps/step-calendar'
import stepFoodies from './steps/step-foodies'
import stepResume from './steps/step-resume'
import stepDiscount from './steps/step-discount'
import StepFoodiesSelected from './steps/step-foodies-selected'
import stepRequirements from './steps/step-requirements'
import { mapGetters, mapState } from 'vuex'

// import { ref } from 'vue'
// TODO ACOMODAR LA VALIDACION DEL TEXTO DEL BOTON CONTINUAR
export default {
  name: 'collaborationCreate',
  data () {
    return {
      collaboration: {
        type: 'private',
        contact_name: '',
        contact_phone: '',
        discount_type: 'prc',
        discount_value: 100,
        companions_limit: 1,
        is_specific_day: false,
        start_at: '',
        end_at: '',
        allowed_week_days: [1, 2, 3, 4],
        foodies_meeting: false,
        uses_limit: 0,
        min_ig_followers: 0,
        foodies: [],
        requirements: ''
      },
      disableNext: false,
      foodies: [],
      selectedFoodies: [],
      foodiesSelectedObjects: [],
      modal: {
        create: false,
        error: false,
        uses_limit: false
      },
      loading: {
        createCollab: null
      },
      createCollabText: 'Continuar'
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  components: {
    StepFoodiesSelected,
    step1,
    step2,
    stepFollowers,
    stepFoodiesCount,
    stepCompanions,
    stepCalendar,
    stepDiscount,
    stepFoodies,
    stepResume,
    stepRequirements
  },
  methods: {
    prev (index, goPrev) {
      switch (index) {
        case 1: {
          this.$router.replace({ path: '/collaborations' })
          break
        }
        default: {
          this.disableNext = false
          goPrev()
          break
        }
      }
    },
    getCollabText (index) {
      let createCollabText = 'Continuar'
      switch (index) {
        case 7: {
          if (this.collaboration.type === 'public' && this.collaboration.foodies_meeting) {
            createCollabText = 'Crear Colaboración'
          } else {
            createCollabText = 'Continuar'
          }
          break
        }
        case 8: {
          if (this.collaboration.type === 'private' && this.collaboration.foodies_meeting) {
            createCollabText = 'Crear Colaboración'
          } else {
            createCollabText = 'Continuar'
          }
          break
        }
        case 9: {
          if (this.collaboration.type === 'public' && !this.collaboration.foodies_meeting) {
            createCollabText = 'Crear Colaboración'
          } else {
            createCollabText = 'Continuar'
          }
          break
        }
        case 10: {
          createCollabText = 'Crear Colaboración'
          break
        }
        default: {
          createCollabText = 'Continuar'
          break
        }
      }
      return createCollabText
    },
    stepHandler (index, goNext) {
      switch (index) {
        case 4: {
          if (!this.collaboration.uses_limit && this.collaboration.type === 'public') {
            this.modal.uses_limit = true
          } else {
            goNext()
          }
          break
        }
        case 7: {
          if (this.collaboration.type === 'public' && this.collaboration.foodies_meeting) {
            this.createCollab()
          } else {
            goNext()
          }
          break
        }
        case 8: {
          if (this.collaboration.type === 'private' && this.collaboration.foodies_meeting) {
            this.createCollab()
          } else {
            goNext()
          }
          break
        }
        case 9: {
          if (this.collaboration.type === 'public' && !this.collaboration.foodies_meeting) {
            this.createCollab()
          } else {
            goNext()
          }
          break
        }
        case 10: {
          this.createCollab()
          break
        }
        default: {
          goNext()
        }
      }
      if (this.collaboration.foodies_meeting) {
        this.collaboration.discount_type = 'prc'
        this.collaboration.discount_value = 100
        this.collaboration.companions_limit = 0
        if (this.collaboration.type === 'public') {
          this.collaboration.foodies = []
        }
      }
      // console.log(this.collaboration)
      // console.log(this.foodies)
    },
    createCollab () {
      this.loading.createCollab = true
      this.collaboration.is_specific_day = this.collaboration.start_at === this.collaboration.end_at
      //
      // if (this.collaboration.discount_type === 'fixed_e2') {
      //   this.collaboration.discount_value = this.collaboration.discount_value * 100
      // }
      // if (this.collaboration.foodies_meeting) {
      //   this.collaboration.start_at = this.start_at
      //   this.collaboration.end_at = this.end_at
      // }
      const foodieList = []

      this.collaboration.foodies.forEach(foodieId =>
        foodieList.push({ id: foodieId })
      )

      this.collaboration.foodies = foodieList

      this.$repository.collaborations
        .store({
          ...this.collaboration, ...{ discount_value: this.collaboration.discount_type === 'fixed_e2' ? this.collaboration.discount_value * 100 : this.collaboration.discount_value }
        })
        .then(() => {
          this.modal.create = true
          this.loading.createCollab = false
        })
        .catch(() => (this.modal.error = true))
    }
  },
  created () {
    if (this.isAuthenticated) {
      this.collaboration.contact_name = this.user.restaurant.contact_name
      this.collaboration.contact_phone = this.user.restaurant.phone
      this.collaboration.min_ig_followers = process.env.VUE_APP_NODE_ENV === 'production' ? 1000 : 1

      this.$repository.collaborations
        .requirements()
        .then(({ data }) => {
          this.collaboration.requirements = data.default_requirements ? data.default_requirements : ''
        })
    }
  }
}
</script>

<style scoped>

</style>
