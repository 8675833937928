<template>
  <div class="flex flex-col px-normal pt-sm text-left content-between text-left">
    <div class="flex-1">
      <h3 class="text-xl font-bold">Cantidad de seguidores por foodie</h3>
      <p class="text-sm">Foodie con menor cantidad de seguidores no serán mostrados</p>
    </div>

    <div class="flex-1 flex-col text-center px-normal mt-2">
      <div class="text-primary text-mid2xl custom-min-h">
        <a-loader v-if="loading" class="h-10 w-10 block mx-auto" :color="'text-primary'"/>
        <h2 v-else >{{ totalFoodies }}</h2>
      </div>
      <h2>Foodies disponibles</h2>
      <div class="range-wrap">
        <input :disabled="loading" type="range" v-model="followersCount" class="range mt-4" name="quantity" step="100" min="1000" max="30000" @change="fetchFollowers">
        <div class="flex text-xl">
          <h1 class="flex-1 text-left">1k</h1>
          <h2 class="flex-1 text-center">
            {{ followersCount }}
            Seguidores
          </h2>
          <h2 class="flex-1 text-right">30k</h2>
        </div>
<!--        <output class="bubble"></output>-->
      </div>
    </div>
    <div class="flex-1 flex items-end pb-4">

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'step-followers',
  props: {
    followers: { required: false },
    foodies: { required: false }
  },
  emits: ['update:followers', 'update:foodies', 'disable-next'],
  data () {
    return {
      totalFoodies: '',
      followersCount: 0,
      foodiesList: [],
      loading: false
    }
  },
  methods: {
    test () {
      console.log(this.followersCount)
    },
    fetchFollowers () {
      this.$emit('disable-next', true)
      this.loading = !this.loading
      this.$emit('update:followers', this.followersCount)
      axios
        .get(`${process.env.VUE_APP_NODE_ENV !== 'dev' ? process.env.VUE_APP_PRODUCTION_API_URL : process.env.VUE_APP_DEVELOPMENT_API_URL}/foodies?ig_followers_count=>${this.followersCount}&explore=1`)
        .then(({ data: response }) => {
          this.foodiesList = response.data
          this.$emit('update:foodies', response.data)
          this.totalFoodies = response.total
        })
        .catch((response) => (console.log(response)))
        .finally(() => {
          this.loading = !this.loading
          this.$emit('disable-next', false)
        })
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.followersCount = this.followers
    this.fetchFollowers()
    const allRanges = document.querySelectorAll('.range-wrap')
    allRanges.forEach(wrap => {
      const range = wrap.querySelector('.range')
      const bubble = wrap.querySelector('.bubble')

      range.addEventListener('input', () => {
        setBubble(range, bubble)
      })
      setBubble(range, bubble)
    })
    function setBubble (range, bubble) {
      const val = range.value
      const min = range.min ? range.min : 0
      const max = range.max ? range.max : 100
      const newVal = Number(((val - min) * 100) / (max - min))
      bubble.innerHTML = val

      // Sorta magic numbers based on size of the native UI thumb
      bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`
    }
  }
}
</script>

<style scoped>
.range-wrap {
  position: relative;
  margin: 0 auto 3rem;
}
.range {
  width: 100%;
}
.bubble {
  background: red;
  color: white;
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}
.bubble::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: red;
  top: -1px;
  left: 50%;
}
.custom-min-h{
  min-height: 45px;
}
</style>
