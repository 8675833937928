<template>
  <div class="flex flex-col px-normal pt-sm text-left content-between text-left">
    <div class="flex-none">
      <h3 class="text-xl font-bold">Resumen de la colaboración</h3>
      <div v-if="collaboration.type === 'public'">
        <p v-if="collaboration.foodies_meeting" class="text-sm text-primary">Foodie Quedada Pública</p>
        <p v-else class="text-sm text-primary">Colaboración Pública</p>
      </div>
      <div v-else>
        <p v-if="collaboration.foodies_meeting" class="text-sm text-primary">Foodie Quedada por invitación</p>
        <p v-else class="text-sm text-primary">Colaboración por invitación</p>
      </div>
    </div>

    <div v-if="collaboration.type === 'private'" class="flex-1 py-normal">
      <div v-if="!collaboration.foodies_meeting" class="mt-normal">
        <i class="icon-calendar-01 pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          Del {{ startAt }} al {{ endAt }}
        </p>
      </div>
      <div v-else class="mt-normal">
        <i class="icon-calendar-01 pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg capitalize align-middle">
          {{ $filters.moment(collaboration.start_at, 'dddd DD/MM/YY') }}
        </p>
      </div>

      <div v-if="collaboration.foodies_meeting" class="mt-normal">
        <i class="icon-clock pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg capitalize align-middle">
          {{ $filters.moment(collaboration.start_at, 'HH:mm') + 'h' }}
        </p>
      </div>

      <div v-if="!collaboration.foodies_meeting" class="mt-normal">
        <i class="icon-table-01 pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          Una mesa por foodie
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-userGroup pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          {{ collaboration.foodies.length }}{{ collaboration.foodies_meeting ? ' Plazas disponibles' : ' Foodies invitados' }}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-tc pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          {{ collaboration.discount_value + (collaboration.discount_type === 'prc' ? '% ' : '€ ') + 'de descuento'}}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-add_foodie pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          Max. Acompañantes: {{ collaboration.foodies_meeting ? 0 : collaboration.companions_limit}}
        </p>
      </div>

      <div v-if="!collaboration.foodies_meeting" class="mt-normal">
        <i class="icon-clock pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          {{ collaboration.allowed_week_days.length > 4 ? 'Toda la semana' : 'De lunes a jueves'}}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-coupon pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg">
          Requerimientos
        </p>
      </div>

      <div class="mt-normal">
        <p class="inline-block text-md mx-10">
          {{ collaboration.requirements ? collaboration.requirements : 'Sin requerimientos' }}
        </p>
      </div>

      <div class="pb-32 mt-normal">
        <div v-for="foodie in foodieValues" :key="foodie" class="py-2">
          <div class="flex border rounded-full w-full p-1 items-center">
            <a-initials :src="foodie.profile_picture" :name="foodie.name" class="flex-none"/>
            <h2 class="pl-2 flex-auto">{{ foodie.ig_username ? '@' + foodie.ig_username : foodie.name }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div v-if="collaboration.type === 'public'" class="flex-1 py-normal">
      <div v-if="!collaboration.foodies_meeting" class="mt-normal">
        <i class="icon-calendar-01 pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          Del {{ startAt }} al {{ endAt }}
        </p>
      </div>
      <div v-else class="mt-normal">
        <i class="icon-calendar-01 pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg capitalize align-middle">
          {{ $filters.moment(collaboration.start_at, 'dddd DD/MM/YY') }}
        </p>
      </div>

      <div v-if="collaboration.foodies_meeting" class="mt-normal">
        <i class="icon-clock pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg capitalize align-middle">
          {{ $filters.moment(collaboration.start_at, 'HH:mm') + 'h' }}
        </p>
      </div>

      <div v-if="!collaboration.foodies_meeting" class="mt-normal">
        <i class="icon-table-01 pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          Una mesa por foodie
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-foodies pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          {{ collaboration?.uses_limit + ' Plazas Disponibles'}}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-tc pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          {{ collaboration.discount_value + (collaboration.discount_type === 'prc' ? '% ' : '€ ') + 'de descuento'}}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-add_foodie pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          Max. Acompañantes: {{ collaboration.foodies_meeting ? 0 : collaboration.companions_limit}}
        </p>
      </div>

      <div v-if="!collaboration.foodies_meeting" class="mt-normal">
        <i class="icon-clock pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg align-middle">
          {{ collaboration.allowed_week_days.length > 4 ? 'Toda la semana' : 'De lunes a jueves'}}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-coupon pr-4 inline-block text-xxl align-middle"></i>
        <p class="inline-block text-lg">
          Requerimientos
        </p>
      </div>

      <div class="mt-normal">
        <p class="inline-block text-md mx-10">
          {{ collaboration.requirements ? collaboration.requirements : 'Sin requerimientos' }}
        </p>
      </div>
    </div>

    <div class="flex-1 flex items-end pb-4">
    </div>
  </div>

  <a-alert hide-cancel :show="createModal" :confirm-class="'text-center text-primary block mx-auto'" :confirm-text="'Ver mis colaboraciones'" @confirm="$router.push({ path: '/collaboration' })">
    <div class="text-center font-bold text-lg block mx-auto w-4/5 mt-sm">
      <h2>¡Enhorabuena!</h2>
      <h2>Tu colaboración ha sido creada</h2>
    </div>
  </a-alert>

  <a-alert hide-cancel :show="errorModal" :confirm-class="'text-center text-primary block mx-auto'" :confirm-text="'Ver mis colaboraciones'" @confirm="$router.push({ path: '/collaboration' })">
    <div class="text-center font-bold text-lg block mx-auto w-4/5 mt-sm">
      <h2 class="text-pointred">¡Error!</h2>
      <h2>No se ha podido crear la colaboración</h2>
    </div>
  </a-alert>
</template>

<script>
export default {
  name: 'step-resume',
  props: {
    collaboration: { required: false, type: Object },
    createModal: { required: false, type: Boolean, default: false },
    errorModal: { required: false, type: Boolean, default: false },
    foodieValues: { required: false, type: Object }
  },
  data () {
    return {
      startAt: '',
      endAt: '',
      discount: 0,
      foodiesSelected: []
    }
  },
  methods: {
    format () {
      return this.$filters.moment(this.collaboration.start_at, 'dd DD/MM/YY')
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.startAt = this.$filters.moment(this.collaboration.start_at, 'DD/MM/YY')
    this.endAt = this.$filters.moment(this.collaboration.end_at, 'DD/MM/YY')
    this.discount = this.collaboration.discount
  }
}
</script>

<style scoped>

</style>
