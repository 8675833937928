<template>
  <div class="flex flex-col px-normal pt-sm text-left content-between text-left">
    <div class="flex-1">
      <h3 class="text-xl font-bold">¿Con cuantos foodies desea colaborar?</h3>
    </div>

    <div class="fex-1 block custom-width mx-auto justify-items-center mt-2">
      <div class="flex-1 grid grid-cols-3 gap-4 text-center items-center">
        <button @click="buttonValue(1)" class="option custom-button-count active:bg-primary">1</button>
        <button @click="buttonValue(2)" class="option custom-button-count active:bg-primary">2</button>
        <button @click="buttonValue(3)" class="option custom-button-count active:bg-primary">3</button>
        <button @click="buttonValue(4)" class="option custom-button-count active:bg-primary">4</button>
        <button @click="buttonValue(5)" class="option custom-button-count active:bg-primary">5</button>
        <button @click="buttonValue(6)" class="option custom-button-count active:bg-primary">6</button>
        <button @click="buttonValue(7)" class="option custom-button-count active:bg-primary">7</button>
        <button @click="buttonValue(8)" class="option custom-button-count active:bg-primary">8</button>
        <button @click="buttonValue(9)" class="option custom-button-count active:bg-primary">9</button>
        <div></div>
        <button @click="buttonValue(0)" class="option custom-button-count active:bg-primary">0</button>
        <button @click="eraseButton"  class="option custom-button-count-cancel text-dgray text-2xl" style="font-size: 40px"><i class="icon-back_cance-01"></i></button>
      </div>
      <div class="flex-1 flex items-center mt-normal">
        <div class="border-b-2 border-primary w-4/5 block mx-auto mt-normal text-center text-xxl">{{ companionsArray.join('') }}</div>
      </div>
    </div>
    <div class="flex-1"></div>
  </div>
  <a-alert hide-cancel :show="modal" :confirm-class="'text-center text-primary block mx-auto'" @confirm="$emit('update:modal', false)">
    <div class="text-center font-bold text-lg block mx-auto w-4/5 mt-sm">
      <h2>Debes ingresar un número</h2>
    </div>
  </a-alert>
</template>

<script>
export default {
  name: 'step-companions',
  props: {
    companions: { required: false },
    modal: { required: false, type: Boolean, default: false }
  },
  emits: ['update:companions', 'update:modal'],
  data () {
    return {
      companionsValue: 0,
      companionsArray: [0],
      lastValue: 0
      // buttonValue: 0
    }
  },
  methods: {
    buttonValue (value) {
      if (this.companionsArray[0] === 0) {
        this.companionsArray[0] = value
      } else {
        this.companionsArray.push(value)
        // this.companionsValue += value.toString()
      }
      this.format()
      // this.$emit('update:companions', value.parseInt())
    },
    eraseButton () {
      if (this.companionsArray.length > 1) {
        this.companionsArray.pop()
      } else {
        this.companionsArray[0] = 0
      }
      this.format()

      // this.companionsArray.pop()
      // if(this.compaion)
    },
    format () {
      this.companionsValue = this.companionsArray.toString()
      this.companionsValue = +this.companionsArray.join('')
      this.$emit('update:companions', this.companionsValue)
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.companionsValue = this.companions.toString()
    this.companionsArray = this.companionsValue
      .split('')
      .map(char => parseInt(char))
  }
}
</script>

<style scoped>
.wrapper .option{
  align-items: center;
  justify-content: space-evenly;
  border-radius: 50%;
  cursor: pointer;
  background: #efefef;
  transition: all 0.3s ease;
}
.option {
  min-width: 50px !important;
  min-height: 50px !important;
  width: 50px !important;
  height: 50px !important;
}
input[type="radio"]{
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3,
#option-4:checked:checked ~ .option-4,
#option-5:checked:checked ~ .option-5,
#option-0:checked:checked ~ .option-0{
  /*border-color: #cf1a1a;*/
  background-color: #397EF3;
  color: #fff;
}

#option-1:checked:checked ~ .option-1 button,
#option-2:checked:checked ~ .option-2 button,
#option-3:checked:checked ~ .option-3 button,
#option-4:checked:checked ~ .option-4 button,
#option-5:checked:checked ~ .option-5 button,
#option-0:checked:checked ~ .option-0 button{
  /*border-color: #cf1a1a;*/
  background-color: #397EF3;
  border: none;
}
.custom-button-count:active {
  color: #fff;
}
.custom-button-count-cancel:active {
  color: #397EF3;
}

@layer utilities {
  .custom-button-count {
    @apply rounded-full border border-dgray bg-lowgray text-dgray;
  }
}
</style>
