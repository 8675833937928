<template>
  <div class="px-normal pt-sm text-left pb-28">
    <div class="flex-none block">
      <div class="">
        <h3 class="text-xl font-bold">Foodies seleccionados ({{ selectedFoodies.length }})</h3>
      </div>

      <div class="wrapper my-2">
        <input type="radio" name="select" id="option-1" value="All" v-model="picked" @change="activeComponent = $event.target.value">
        <input type="radio" name="select" id="option-2" value="Solicitudes" v-model="picked" @change="activeComponent = $event.target.value">
        <input type="radio" name="select" id="option-3" value="Favorites" v-model="picked" @change="activeComponent = $event.target.value">
        <label for="option-1" class="option option-1 pr-2">
          <span class="">
            TODOS
          </span>
        </label>
        <label for="option-2" class="option option-2 pr-2">
          <span class="">
            SOLICITUDES
          </span>
        </label>
        <label for="option-3" class="option option-3">
          <span class="">
            FAVORITOS
          </span>
            <!--          <button :disabled="!collabType" @click="$emit('go-next')" class="border border-dgray rounded-full px-4 py-small">Elegir</button>-->
        </label>
      </div>

    </div>

    <component
      :is="activeComponent"
      :followers="followers"
      :selected-foodies="foodiesSelected"
      @foodie-picked-all="foodieHandler"
      @foodie-picked-solicitude="foodieHandler"
      @foodie-picked-favorites="foodieHandler"
      @disable-next="disableNext"
    />
<!--    <All-->
<!--      :followers="followers"-->
<!--      v-model:selected-foodies="selectedFoodies"-->
<!--      @foodie-picked-all="foodieHandler" />-->
<!--    <Solicitudes-->
<!--      v-else-if="activeComponent = 2"-->
<!--      v-model:selected-foodies="selectedFoodies"-->
<!--      @foodie-picked-solicitude="foodieHandler" />-->
<!--    <Favorites-->
<!--      v-else-if="activeComponent = 3"-->
<!--      v-model:selected-foodies="selectedFoodies"-->
<!--      @foodie-picked-favorites="foodieHandler" />-->
  </div>
</template>

<script>
import All from './extraViews/all'
import Solicitudes from './extraViews/solicitude'
import Favorites from './extraViews/favorites'
export default {
  components: {
    All,
    Solicitudes,
    Favorites
  },
  props: {
    foodiesList: { required: false },
    foodiesSelected: { required: false },
    followers: { required: false }
  },
  data () {
    return {
      foodies: [],
      search: true,
      searchValue: '',
      selectedFoodies: [],
      picked: '',
      activeComponent: 'All'
    }
  },
  emits: ['update:foodiesSelected', 'disable-next'],
  methods: {
    disableNext () {
      // this.$emit('disable-next', value)
      if (this.selectedFoodies.length > 0) {
        this.$emit('disable-next', false)
      } else {
        this.$emit('disable-next', true)
      }
      //
    },
    foodieHandler (foodie) {
      // const find = this.selectedFoodies.includes(foodie)
      const find = this.selectedFoodies.find(value => value.id === foodie.id)
      if (find) {
        const finded = this.selectedFoodies.find(value => value.id === foodie.id)
        const pos = this.selectedFoodies.indexOf(finded)
        this.selectedFoodies.splice(pos, 1)
      } else {
        this.selectedFoodies.push(foodie)
      }
      this.$emit('update:foodiesSelected', this.selectedFoodies)
      if (this.selectedFoodies.length > 0) {
        this.$emit('disable-next', false)
      } else {
        this.$emit('disable-next', true)
      }
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.foodies = this.foodieList
    this.selectedFoodies = this.foodiesSelected
    this.picked = 'All'
    this.$emit('update:foodiesSelected', this.selectedFoodies)
  }
}
</script>

<style scoped>
.wrapper .option{
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  transition: all 0.3s ease;
}
input[type="radio"]{
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3{
  /*border-color: #cf1a1a;*/
  color: #397EF3;
}

</style>
