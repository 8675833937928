<template>
  <div class="text-left my-small flex border border-color-gray rounded-full items-center overflow-hidden mb-sm">
    <input
      ref="search"
      autofocus
      v-debounce:[400]="search => fetchFollowers( {search: search})"
      placeholder="Buscar Foodies"
      class="flex-auto bg-opacity-75 bg-white text-black focus:outline-none p-2">
    <i class="icon-search inline flex-none p-2 text-xl" />
  </div>

  <a-list
    class="flex flex-col gcp-restaurant-explore"
    :source="pagination.data"
    :loading="loading"
    :should-emit-bottom-reached="pagination.next_page_url !== null"
    @bottom-reached="fetch({ page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page, ig_followers_count: '>' + followers }, true)"
    #default="{ item }">
    <div class="border rounded-2xl mb-2">
      <div class="flex items-center text-left px-small transition-all duration-100 py-2">
        <div class="flex-none cursor-pointer rounded-full" >
          <div class="grid-thumb-image flex-initial align-middle left-0 initials-space">
            <a-initials :name="item.name" :src="item.profile_picture"></a-initials>
          </div>
        </div>
        <div class="flex-auto cursor-pointer pl-small">
          <p class="font-normal" style="word-break: break-all;">{{ item.name }}</p>
          <p v-if="item.ig_username !== null" class="font-light" style="word-break: break-all;">{{ '@' + item.ig_username }}</p>
        </div>
        <div class="flex-none pr-2">
          <div class="flex border rounded-full h-8 w-8 text-center items-center">
            <i class="flex-auto text-lg" :class="item.favorite ? 'icon-heart text-red-400' : 'icon-heartEmpty'" />
          </div>
        </div>
        <div class="flex-none">
          <button
            @click="$emit('foodie-picked-all', item)"
            class="rounded-full text-center"
            style="width: 80px;padding-top: 5px; padding-bottom: 5px"
            :class="selectedFoodies.find(value => value.id === item.id) ? 'bg-gray text-white' : 'bg-primary text-white'"
          >{{ selectedFoodies.find(value => value.id === item.id) ? 'Añadido' : 'Añadir'}}
          </button>
        </div>
      </div>
      <div class="grid pt-3 border-t items-end text-center" :class="item?.completed_collabs_count ? 'grid-cols-3' : 'grid-cols-2'">
        <div class="flex-1 flex-col flex-grow flex-wrap">
          <h4>{{ formattedFollowers(item?.ig_followers_count) }}</h4>
          <h4 class="text-gray">Seguidores</h4>
        </div>
        <div v-if="item?.completed_collabs_count" class="flex-1 flex-col flex-grow flex-wrap">
          <h4>{{ item?.completed_collabs_count}}</h4>
          <h4 class="text-gray">Colabs</h4>
        </div>
        <div class="flex flex-col flex-grow flex-wrap">
          <h4>{{ item?.ig_engagement_prc + ' %'}}</h4>
          <h4 class="text-gray">Engagement</h4>
        </div>
      </div>
    </div>
  </a-list>
</template>

<script>

export default {
  name: 'all',
  props: {
    foodies: { required: false },
    selectedFoodies: { required: false },
    followers: { required: false }
  },
  emits: ['disable-next', 'foodie-picked-all', 'foodie-picked-solicitude', 'foodie-picked-favorites'],
  data () {
    return {
      pagination: { data: [] },
      loading: false,
      query: {
        limit: 25,
        // sort_by: 'created_at,desc',
        explore: 1,
        with_count: 'completedCollabs'
      },
      foodieList: [],
      search: ''
    }
  },
  methods: {
    formattedFollowers (followers) {
      if (followers >= 1100) {
        return parseFloat(followers / 1000).toFixed(1) + 'k'
      }
      return followers
    },
    fetch (segment = {}, appending = false) {
      this.loading = !this.loading
      const query = { ...this.query, ...segment }
      this.$repository.foodies
        .index(query)
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination

          this.query = query
        })
        .finally(() => (this.loading = !this.loading))
    },
    fetchFollowers (segment = {}, appending = false) {
      this.$emit('disable-next', true)
      const query = { ...this.query, ...segment }
      this.loading = !this.loading
      this.$repository.foodies
        .followers(this.followers, query)
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination
        })
        .catch((response) => (console.log(response)))
        .finally(() => {
          this.loading = !this.loading
          this.$emit('disable-next', false)
        })
    }
  },
  mounted () {
    this.foodieList = this.selectedFoodies
    this.fetchFollowers()
  }
}
</script>

<style scoped>

</style>
